import Api from '@/services/Index';

const sendSms = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/send-sms', formData);
}

const verifySms = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/verify-sms', formData);
}

const saveProgramPerference = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/preference', formData);
}

const getProgramPerferences = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/preference/index', formData);
}

const deleteProgramPerference = async (id, config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/graduate/application/preference/'+id, config);
}

const saveEducation = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/education', formData);
}

const deleteEducation = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/graduate/application/education/'+id, formData);
}

const saveExam = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/exam', formData);
}

const deleteExam = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/graduate/application/exam', config);
}

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/graduate/applications', config);
}

const saveAll = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/save', formData);
}

const requiredDocuments = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/documents', formData);
}

const uploadDocument = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/upload', formData);
}

const getAllPerferences = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/preference/index', config);
}

const sendApprove = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/send-approve', formData);
}

const downloadDocument = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/download-document/'+id, formData,{responseType: 'arraybuffer'});
}

const show = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/show', formData);
}

const updatePhoneSendVerify = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/update-phone/send-sms', formData);
}

const updatePhoneVerify = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/update-phone/verify-sms', formData);
}

const educationSave = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/education/save', formData);
}

const contactInfoSave = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/contact-information', formData);
}

const preferanceSave = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/preference/save', formData);
}

const excelExport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    config.responseType = 'arraybuffer'
    return Api.get('/graduate/applications/excel-export', config);
}
const exceLogExport = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/graduate/applications/logs/excel-export');
}

const excelExportMeeting = (config)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    config.responseType = 'arraybuffer'
    return Api.get('/graduate/application/meeting/excel-export', config);
}

const excelImportMeeting = (formData)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/meeting/excel-import',formData)
}

const preferenceStatusChange = async (id,formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/preference/change-status/'+id, formData);
}

const scientificPreparationCourseSave = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/preference/scientific-preparation', formData);
}

const scholarshipSave = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/preference/scholarship', formData);
}

const deleteFile = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/graduate/application/delete-file', config);
}

const deleteScholarship = async (code,config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/graduate/application/preference/scholarship/'+code, config);
}

const studentStatusSave = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/preference/student-status', formData);
}

const changeDocumentStatus = async (id,formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/graduate/application/approve-document/'+id, formData);
}

const meetingsGetAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/graduate/application/meeting/index', config);
}

const saveMeeting = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/meeting', formData);
}
const updateMeeting = async (id,config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/graduate/application/meeting/update/'+id, config);
}
const applicationPdf = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    let config = {}
    config.responseType = 'arraybuffer'
    return Api.get('/graduate/application/preregistration-tracking-document/'+id, config);
}

const scholarships = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/scholarships', config);
}

const getCourseTransfers = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/preference/course-transfer/index', config);
}

const yokisInfoSearch = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/education/yoksis-information', formData);
}

const osymInfoSearch = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/graduate/application/exam/osym-information', formData);
}

const pinReminder = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/graduate/application/pin-reminder", data);
};

export default {
    sendSms,
    verifySms,
    saveProgramPerference,
    getProgramPerferences,
    deleteProgramPerference,
    saveEducation,
    deleteEducation,
    saveExam,
    getAll,
    deleteExam,
    saveAll,
    requiredDocuments,
    uploadDocument,
    getAllPerferences,
    sendApprove,
    downloadDocument,
    show,
    updatePhoneSendVerify,
    updatePhoneVerify,
    educationSave,
    contactInfoSave,
    preferanceSave,
    excelExport,
    preferenceStatusChange,
    scientificPreparationCourseSave,
    scholarshipSave,
    deleteFile,
    deleteScholarship,
    studentStatusSave,
    changeDocumentStatus,
    meetingsGetAll,
    saveMeeting,
    applicationPdf,
    scholarships,
    excelExportMeeting,
    excelImportMeeting,
    getCourseTransfers,
    updateMeeting,
    exceLogExport,
    yokisInfoSearch,
    osymInfoSearch,
    pinReminder
}
